<template>
  <b-container>
    <h2>{{ $t('profile.account.title') }}</h2>

    <div class="profile-photo">
      <ValidationProvider rules="image|size:2000" ref="file_provider" v-slot="{ errors, classes, invalid }">
        <input
          type="file"
          ref="profile_photo_file"
          @change="uploadUserProfilePhoto"
          v-if="!userHasPhoto"
          class="profile-photo-upload"
          :class="classes"
          accept="image/png, image/jpeg, image/jpg, image/gif"
        >

        <div class="invalid-file" v-if="invalid">{{ errors[0] }}</div>
      </ValidationProvider>

      <div class="action-overlay">
        <i
          class="fas fa-plus"
          v-if="!userHasPhoto"
        ></i>

        <i
          class="fas fa-minus"
          v-if="userHasPhoto"
          @click="deleteUserProfilePhoto"
        ></i>
      </div>
      <img class="rounded-circle" :src="userPhotoUrl">
    </div>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(updateAccountData)">
        <base-box class="box">
          <h4>{{ $t('profile.account.personal_info_title') }}</h4>

          <b-row>
            <b-col md="6" class="input-mobile-margin">
              <ValidationProvider rules="required|max:50" v-slot="{ classes, errors }">

                <base-input
                  v-model="current_user.name"
                  type="text"
                  :placeholder="$t('profile.account.name_placeholder')"
                  :cssClass="classes"
                  :validationMessage="errors[0]"
                  autofocus
                />
              </ValidationProvider>
            </b-col>

            <b-col md="6">
              <ValidationProvider rules="required|max:50" v-slot="{ classes, errors }">

                <base-input
                  v-model="current_user.surname"
                  type="text"
                  :placeholder="$t('profile.account.surname_placeholder')"
                  :cssClass="classes"
                  :validationMessage="errors[0]"
                />
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row class="mb-0">
            <b-col md="6">
              <ValidationProvider rules="required|max:50|email" v-slot="{ classes, errors }">

                <base-input
                  v-model="current_user.email"
                  type="email"
                  :placeholder="$t('profile.account.email_placeholder')"
                  :cssClass="classes"
                  :validationMessage="errors[0]"
                  disabled
                />
              </ValidationProvider>

              <div class="mt-1 ml-1">
                <p class="mb-0" v-html="$t('profile.account.email_change_info', {'info_email': env.info_email})"></p>
              </div>
            </b-col>

           <b-col md="6">
              <ValidationProvider rules="required|max:50" v-slot="{ classes, errors }">
                <base-input
                  v-model="current_user.address"
                  type="text"
                  :placeholder="$t('profile.account.address_placeholder')"
                  :cssClass="classes"
                  :validationMessage="errors[0]"
                />
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>

            <b-col md="6">
              <ValidationProvider rules="required|max:50" v-slot="{ classes, errors }">
                <base-input
                  v-model="current_user.city"
                  type="text"
                  :placeholder="$t('profile.account.city_placeholder')"
                  :cssClass="classes"
                  :validationMessage="errors[0]"
                />
              </ValidationProvider>
            </b-col>

            <b-col md="6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <base-select
                  v-model="current_user.country_id"
                  :options="countries.countries"
                  :placeholder="$t('profile.account.country_placeholder')"
                  value-field="id"
                  text-field="name"
                  :cssClass="classes"
                  :validationMessage="errors[0]"
                ></base-select>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <ValidationProvider rules="required|max:8" v-slot="{ classes, errors }">
                <base-input
                  v-model="current_user.zip"
                  type="text"
                  :placeholder="$t('profile.account.zip_placeholder')"
                  :cssClass="classes"
                  :validationMessage="errors[0]"
                />
              </ValidationProvider>
            </b-col>

            <b-col md="6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">

                <base-select
                  v-model="current_user.time_zone_id"
                  :options="time_zones.time_zones"
                  :placeholder="$t('profile.account.time_zone_placeholder')"
                  value-field="id"
                  text-field="name"
                  :cssClass="classes"
                  :validationMessage="errors[0]"
                ></base-select>
              </ValidationProvider>
            </b-col>
          </b-row>
        </base-box>

        <div class="text-center">
          <base-button
            type="submit"
            class="btn-lg btn-save-changes"
          >{{ $t('profile.account.save_changes_btn') }}</base-button>

        </div>
      </form>
    </ValidationObserver>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(changePassword)">
        <base-box class="box change-password-box">
          <h4>{{ $t('profile.account.change_password_title') }}</h4>

          <b-row>
            <b-col md="6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <base-input
                  v-model="current_user.current_password"
                  type="password"
                  :placeholder="$t('profile.account.current_password_placeholder')"
                  :cssClass="classes"
                  :validationMessage="errors[0]"
                />
              </ValidationProvider>
            </b-col>
        </b-row>

          <b-row>
            <b-col md="6">
              <ValidationProvider rules="required|min:8" v-slot="{ classes, errors }" vid="password_confirmation">
                <base-input
                  v-model="current_user.password"
                  type="password"
                  :placeholder="$t('profile.account.new_password_placeholder')"
                  :cssClass="classes"
                  :validationMessage="errors[0]"
                />
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <ValidationProvider rules="required|confirmed:password_confirmation" v-slot="{ classes, errors }">
                <base-input
                  v-model="current_user.password_confirmation"
                  type="password"
                  :placeholder="$t('profile.account.repeat_password_placeholder')"
                  :cssClass="classes"
                  :validationMessage="errors[0]"
                />
              </ValidationProvider>
            </b-col>
          </b-row>

          <div class="text-center mt-5">
            <base-button
              type="submit"
              class="btn-lg"
            >{{ $t('profile.account.change_password_btn') }}</base-button>

          </div>
        </base-box>
      </form>
    </ValidationObserver>

    <div class="delete-account">
      <h4>{{ $t('profile.account.delete_account') }}</h4>
      <p v-html="$t('profile.account.delete_account_text', {'support_email': env.support_email})"></p>
      <p>
        {{ $t('profile.account.you_can') }}
        <a @click="download_account_data">{{ $t('profile.account.download_your_information') }}</a>
        {{ $t('profile.account.or') }}
        <a @click="toggle_popup_visibility()">{{ $t('profile.account.delete_the_account') }}</a>{{ $t('profile.account.step_can_not_reversed') }}
      </p>
      <base-popup
        v-show="popup_visibility"
        @close="toggle_popup_visibility"
      >
        <h4>{{ $t('profile.account.account_will_be_deleted') }}</h4>
        <div>
          <button class="btn btn-sm btn-danger mr-2" @click="delete_account">
            {{ $t('profile.account.yes') }}
          </button>
          <button class="btn btn-sm btn-primary" @click="toggle_popup_visibility()">
            {{ $t('profile.account.no') }}
          </button>
        </div>
      </base-popup>
    </div>
  </b-container>
</template>

<script>
import BaseBox from '../../components/BaseBox.vue'
import BaseInput from '../../components/BaseInput.vue'
import BaseSelect from '../../components/BaseSelect.vue'
import BaseButton from '../../components/BaseButton.vue'
import BasePopup from '../../components/BasePopup.vue'

import { ValidationObserver, ValidationProvider } from '../../utils/form_validator'
import { mapState, mapGetters } from 'vuex'

export default {
  data () {
    return {
      popup_visibility: false,
      env: {
        support_email: process.env.VUE_APP_SUPPORT_EMAIL,
        info_email: process.env.VUE_APP_INFO_EMAIL
      }
    }
  },
  components: {
    BaseBox,
    BaseInput,
    BaseSelect,
    BaseButton,
    ValidationObserver,
    ValidationProvider,
    BasePopup
  },
  methods: {
    async uploadUserProfilePhoto (e) {
      const { valid } = await this.$refs.file_provider.validate(e)

      if (valid) {
        this.$store.dispatch('profile/account/updateCurrentUserProfilePhoto', {
          profile_photo: this.$refs.profile_photo_file.files[0],
          user_id: this.current_user.id
        }).then(() => {
          this.$notify({
            type: 'success',
            text: this.$t('profile.account.profile_photo.updated')
          })
        })
      }
    },
    deleteUserProfilePhoto () {
      if (confirm(this.$t('profile.account.profile_photo.deleted_confirm'))) {
        this.$store.dispatch('profile/account/deleteCurrentUserProfilePhoto', {
          user_id: this.current_user.id
        }).then(() => {
          this.$notify({
            type: 'success',
            text: this.$t('profile.account.profile_photo.deleted')
          })
        })
      }
    },
    async updateAccountData () {
      this.$store.dispatch('profile/account/updateCurrentUser', {
        current_user: this.current_user
      }).then(() => {
        this.$notify({
          type: 'success',
          text: this.$t('profile.account.updated')
        })
      })
    },
    changePassword () {
      this.$store.dispatch('profile/account/updateCurrentUserPassword', {
        current_user: this.current_user
      }).then((response) => {
        if (response.data.status === false) {
          this.$notify({
            type: 'error',
            text: response.data.message
          })
        } else {
          this.$auth.logout().then(() => {
            this.$notify({
              type: 'success',
              text: this.$t('profile.account.password.updated')
            })
          })
        }
      })
    },
    download_account_data () {
      let csvContent = 'data:text/csv;charset=utf-8,'

      const current_user_country = this.get_country_by_id(this.current_user.country_id).name

      const subscriber_data = `name;surname;email;address;city;country;zip
        ${this.current_user.name};${this.current_user.surname};${this.current_user.email};${this.current_user.address};${this.current_user.city};${current_user_country};${this.current_user.zip}`

      csvContent += subscriber_data

      const data = encodeURI(csvContent)
      const link = document.createElement('a')

      link.setAttribute('href', data)
      link.setAttribute('download', 'account_data.csv')

      link.click()
      link.remove()
    },
    delete_account () {
      this.$store.dispatch('profile/account/deleteAccount', {
        current_user: this.current_user
      }).then((response) => {
        this.$auth.logout().then(() => {
          this.$notify({
            type: 'success',
            text: this.$t('auth.account_deleted')
          })
        })
      })
    },
    toggle_popup_visibility () {
      this.popup_visibility = !this.popup_visibility
    }
  },
  computed: {
    ...mapState({
      current_user: state => state.profile.account.current_user,
      countries: 'countries',
      time_zones: 'time_zones'
    }),
    ...mapGetters({
      userPhotoUrl: 'profile/account/userPhotoUrl',
      userHasPhoto: 'profile/account/userHasPhoto',
      get_country_by_id: 'countries/get_country_by_id'
    })
  },
  mounted: function () {
    this.$store.dispatch('countries/loadCountries')
    this.$store.dispatch('time_zones/loadTimeZones')
  }
}
</script>

<style scoped lang="scss">
.container {
  max-width: 780px;
}

h2 {
  font-size: 26px;
  text-align: center;
  color: var(--charcoal-grey-white);
  font-weight: normal;
  margin: 45px 0 40px 0;
}

h4 {
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-grey-white);
  margin-bottom: 14px;
  margin-left: -5px;
}

p {
  font-size: 11px;
  font-weight: 300;
  line-height: 1.2;
  color: var(--dark-grey-white);
}

.profile-photo {
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 150px;
  position: relative;

  .invalid-file {
    position: absolute;
    border: 2px solid var(--dark-coral);
    color: var(--dark-coral);
    border-radius: 100%;
    width: 150px;
    height: 150px;
    font-size: 11px;
    font-weight: 300;
    text-align: center;
    padding-top: 52px;
  }

  .action-overlay {
    @include basic-transition;
    content: '';
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    cursor: pointer;
    top: 0;
    left: 0;
    color: var(--white-70);
    font-size: 88px;
    opacity: 0;
    z-index: 90;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    .action-overlay {
      background-color: var(--white-opacity03);
      opacity: 1;
    }
  }

  .profile-photo-upload {
    position: absolute;
    opacity: 0%;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    z-index: 100;
    cursor: pointer;
    border-radius: 100%;
  }

  img {
    max-width: 150px;
  }
}

.box {
  margin-bottom: 40px;
  padding: 37px 60px 60px 60px;

  @media (max-width: 991.98px) {
    padding: 37px 45px 45px 45px;
  }

  @media (max-width: 767.98px) {
    padding: 37px 25px 25px 25px;

    .input-mobile-margin {
      margin-bottom: 25px;
    }
  }

  .row {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.btn-save-changes {
  margin-bottom: 40px;
}

.delete-account {
  margin-top: 55px;
  padding-bottom: 140px;

  h4 {
    margin-left: 0;
  }

  p {
    font-family: 'Athelas', arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: 0.1px;
    margin-bottom: 0;
    color: var(--charcoal-grey-white);
  }

  a {
    @include basic-transition;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      opacity: .8;
    }
  }

  ::v-deep .popup-wrapper {
    height: 100%;

    .popup-content {
      text-align: center;
      padding: 30px;
      max-width: 400px;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  ::v-deep .close-popup {
    display: none;
  }

@media (max-width: 575.98px) {
  ::v-deep .base-popup {
    padding: 0 15px;
      .popup-content {
        padding: 20px;
      }
    }
  }
}
</style>

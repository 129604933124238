import i18n from '../../i18n'

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  configure
} from 'vee-validate'

import {
  required,
  email,
  max,
  min,
  confirmed,
  image,
  size,
  integer
} from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: i18n.t('common.validations.required')
})

extend('email', {
  ...email,
  message: i18n.t('common.validations.invalid_email')
})

extend('max', {
  ...max,
  message: i18n.t('common.validations.max_length')
})

extend('min', {
  ...min,
  message: i18n.t('common.validations.min_length')
})

extend('confirmed', {
  ...confirmed,
  message: i18n.t('common.validations.password_confirmed')
})

extend('image', {
  ...image,
  message: i18n.t('common.validations.file_image')
})

extend('size', {
  ...size,
  message: i18n.t('common.validations.file_size')
})

extend('integer', {
  ...integer,
  message: i18n.t('common.validations.integer')
})

configure({
  classes: {
    valid: '',
    invalid: 'is-invalid'
  }
})

export { ValidationObserver, ValidationProvider }

<template>
  <div class="input-wrapper">
    <b-form-select
      @change="handleInput"
      class="form-control"
      :options="options"
      :valueField="valueField"
      :textField="textField"
      :class="cssClass"
      :required="required"
      placeholder=" "
      :disabled="disabled"
      :value="value"
    ></b-form-select>

    <label>{{ placeholder }}</label>

    <div class="invalid-feedback">
      {{ validationMessage }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cssClass: null,
    required: Boolean,
    placeholder: String,
    validationMessage: String,
    disabled: Boolean,
    options: Array,
    valueField: String,
    textField: String,
    value: Number
  },
  methods: {
    handleInput (selected) {
      this.$emit('input', selected)
    }
  }
}
</script>

<style scoped lang="scss">
.input-wrapper {
  position: relative;
  margin-top: 35px;
}

.form-control {
  height: 50px;
  padding: 10px 10px 10px 15px;
  border-radius: 4px;
  border: 1px solid var(--pale-grey-battleship-grey-three);
  font-size: 14px;
  font-weight: 300;
  color: var(--charcoal-grey-white);

  background: url(../assets/arrow-25-icon.svg) no-repeat 10px var(--white-charcoal-grey);
  -webkit-appearance: none;
  background-position-x: calc(100% - 15px);

  &:focus {
    z-index: 2;
  }

  &.is-invalid {
    border: 1px solid var(--light-peach) !important;
    background-image: none;

    &:focus {
      border-color: var(--light-peach);
      box-shadow: none;
    }
  }
}

.invalid-feedback {
  position: absolute;
  margin-top: -20px;
  margin-left: 5px;
  font-size: 11px;
  font-weight: 300;
  color: var(--dark-coral);
}

label {
  position: absolute;
  top: -25px;
  left: 0px;
  display: block;
  width: 100%;
  line-height: 17px;
  font-size: 14px;
  color: var(--charcoal-grey-white);
  font-weight: 300;
}

::placeholder {
  color: transparent;
  opacity: 1;
}
</style>

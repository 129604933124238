var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('h2',[_vm._v(_vm._s(_vm.$t('profile.account.title')))]),_c('div',{staticClass:"profile-photo"},[_c('ValidationProvider',{ref:"file_provider",attrs:{"rules":"image|size:2000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
var invalid = ref.invalid;
return [(!_vm.userHasPhoto)?_c('input',{ref:"profile_photo_file",staticClass:"profile-photo-upload",class:classes,attrs:{"type":"file","accept":"image/png, image/jpeg, image/jpg, image/gif"},on:{"change":_vm.uploadUserProfilePhoto}}):_vm._e(),(invalid)?_c('div',{staticClass:"invalid-file"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])}),_c('div',{staticClass:"action-overlay"},[(!_vm.userHasPhoto)?_c('i',{staticClass:"fas fa-plus"}):_vm._e(),(_vm.userHasPhoto)?_c('i',{staticClass:"fas fa-minus",on:{"click":_vm.deleteUserProfilePhoto}}):_vm._e()]),_c('img',{staticClass:"rounded-circle",attrs:{"src":_vm.userPhotoUrl}})],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateAccountData)}}},[_c('base-box',{staticClass:"box"},[_c('h4',[_vm._v(_vm._s(_vm.$t('profile.account.personal_info_title')))]),_c('b-row',[_c('b-col',{staticClass:"input-mobile-margin",attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('base-input',{attrs:{"type":"text","placeholder":_vm.$t('profile.account.name_placeholder'),"cssClass":classes,"validationMessage":errors[0],"autofocus":""},model:{value:(_vm.current_user.name),callback:function ($$v) {_vm.$set(_vm.current_user, "name", $$v)},expression:"current_user.name"}})]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('base-input',{attrs:{"type":"text","placeholder":_vm.$t('profile.account.surname_placeholder'),"cssClass":classes,"validationMessage":errors[0]},model:{value:(_vm.current_user.surname),callback:function ($$v) {_vm.$set(_vm.current_user, "surname", $$v)},expression:"current_user.surname"}})]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-0"},[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:50|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('base-input',{attrs:{"type":"email","placeholder":_vm.$t('profile.account.email_placeholder'),"cssClass":classes,"validationMessage":errors[0],"disabled":""},model:{value:(_vm.current_user.email),callback:function ($$v) {_vm.$set(_vm.current_user, "email", $$v)},expression:"current_user.email"}})]}}],null,true)}),_c('div',{staticClass:"mt-1 ml-1"},[_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.$t('profile.account.email_change_info', {'info_email': _vm.env.info_email}))}})])],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('base-input',{attrs:{"type":"text","placeholder":_vm.$t('profile.account.address_placeholder'),"cssClass":classes,"validationMessage":errors[0]},model:{value:(_vm.current_user.address),callback:function ($$v) {_vm.$set(_vm.current_user, "address", $$v)},expression:"current_user.address"}})]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('base-input',{attrs:{"type":"text","placeholder":_vm.$t('profile.account.city_placeholder'),"cssClass":classes,"validationMessage":errors[0]},model:{value:(_vm.current_user.city),callback:function ($$v) {_vm.$set(_vm.current_user, "city", $$v)},expression:"current_user.city"}})]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('base-select',{attrs:{"options":_vm.countries.countries,"placeholder":_vm.$t('profile.account.country_placeholder'),"value-field":"id","text-field":"name","cssClass":classes,"validationMessage":errors[0]},model:{value:(_vm.current_user.country_id),callback:function ($$v) {_vm.$set(_vm.current_user, "country_id", $$v)},expression:"current_user.country_id"}})]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('base-input',{attrs:{"type":"text","placeholder":_vm.$t('profile.account.zip_placeholder'),"cssClass":classes,"validationMessage":errors[0]},model:{value:(_vm.current_user.zip),callback:function ($$v) {_vm.$set(_vm.current_user, "zip", $$v)},expression:"current_user.zip"}})]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('base-select',{attrs:{"options":_vm.time_zones.time_zones,"placeholder":_vm.$t('profile.account.time_zone_placeholder'),"value-field":"id","text-field":"name","cssClass":classes,"validationMessage":errors[0]},model:{value:(_vm.current_user.time_zone_id),callback:function ($$v) {_vm.$set(_vm.current_user, "time_zone_id", $$v)},expression:"current_user.time_zone_id"}})]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('base-button',{staticClass:"btn-lg btn-save-changes",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('profile.account.save_changes_btn')))])],1)],1)]}}])}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('base-box',{staticClass:"box change-password-box"},[_c('h4',[_vm._v(_vm._s(_vm.$t('profile.account.change_password_title')))]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('base-input',{attrs:{"type":"password","placeholder":_vm.$t('profile.account.current_password_placeholder'),"cssClass":classes,"validationMessage":errors[0]},model:{value:(_vm.current_user.current_password),callback:function ($$v) {_vm.$set(_vm.current_user, "current_password", $$v)},expression:"current_user.current_password"}})]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|min:8","vid":"password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('base-input',{attrs:{"type":"password","placeholder":_vm.$t('profile.account.new_password_placeholder'),"cssClass":classes,"validationMessage":errors[0]},model:{value:(_vm.current_user.password),callback:function ($$v) {_vm.$set(_vm.current_user, "password", $$v)},expression:"current_user.password"}})]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('base-input',{attrs:{"type":"password","placeholder":_vm.$t('profile.account.repeat_password_placeholder'),"cssClass":classes,"validationMessage":errors[0]},model:{value:(_vm.current_user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.current_user, "password_confirmation", $$v)},expression:"current_user.password_confirmation"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"text-center mt-5"},[_c('base-button',{staticClass:"btn-lg",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('profile.account.change_password_btn')))])],1)],1)],1)]}}])}),_c('div',{staticClass:"delete-account"},[_c('h4',[_vm._v(_vm._s(_vm.$t('profile.account.delete_account')))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('profile.account.delete_account_text', {'support_email': _vm.env.support_email}))}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t('profile.account.you_can'))+" "),_c('a',{on:{"click":_vm.download_account_data}},[_vm._v(_vm._s(_vm.$t('profile.account.download_your_information')))]),_vm._v(" "+_vm._s(_vm.$t('profile.account.or'))+" "),_c('a',{on:{"click":function($event){return _vm.toggle_popup_visibility()}}},[_vm._v(_vm._s(_vm.$t('profile.account.delete_the_account')))]),_vm._v(_vm._s(_vm.$t('profile.account.step_can_not_reversed'))+" ")]),_c('base-popup',{directives:[{name:"show",rawName:"v-show",value:(_vm.popup_visibility),expression:"popup_visibility"}],on:{"close":_vm.toggle_popup_visibility}},[_c('h4',[_vm._v(_vm._s(_vm.$t('profile.account.account_will_be_deleted')))]),_c('div',[_c('button',{staticClass:"btn btn-sm btn-danger mr-2",on:{"click":_vm.delete_account}},[_vm._v(" "+_vm._s(_vm.$t('profile.account.yes'))+" ")]),_c('button',{staticClass:"btn btn-sm btn-primary",on:{"click":function($event){return _vm.toggle_popup_visibility()}}},[_vm._v(" "+_vm._s(_vm.$t('profile.account.no'))+" ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }